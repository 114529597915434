import {
  Camera,
  CameraResultType,
  CameraSource,
  GalleryPhoto,
  Photo,
} from '@capacitor/camera';
import { useIonActionSheet } from '@ionic/react';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';

type ResourceType = GalleryPhoto[] | Photo[];

type CameraGalleryContextProps = {
  clearResource: () => void;
  openActions: () => void;
  resource: ResourceType;
};

type CameraGalleryProviderProps = {
  children: ReactNode;
};

const CameraGalleryContext = createContext<CameraGalleryContextProps>({
  openActions: () => {},
  clearResource: () => {},
  resource: null!,
});

export default function CameraGalleryProvider({
  children,
}: CameraGalleryProviderProps) {
  const [present] = useIonActionSheet();
  const [resource, setResource] = useState<ResourceType>(null!);

  const takePhotoFromCamera = useCallback(() => {
    Camera.getPhoto({
      quality: 100,
      allowEditing: true,
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
    })
      .then((item) => setResource([item]))
      .catch(console.error);
  }, []);

  const photoFromGallery = useCallback(() => {
    Camera.getPhoto({
      quality: 100,
      //allowEditing: true,
      resultType: CameraResultType.Uri,
      source: CameraSource.Photos,
    })
      .then((item) => {
        setResource([item]);
      })
      .catch(console.error);
  }, []);

  const openActions = useCallback(() => {
    present({
      buttons: [
        {
          text: 'câmera',
          handler: takePhotoFromCamera,
        },
        {
          text: 'galeria',
          handler: photoFromGallery,
        },
        {
          text: 'cancelar',
          role: 'cancel',
        },
      ],
      header: 'ações',
    });
  }, [photoFromGallery, present, takePhotoFromCamera]);

  const clearResource = () => setResource(null!);

  return (
    <CameraGalleryContext.Provider
      value={{ openActions, resource, clearResource }}
    >
      {children}
    </CameraGalleryContext.Provider>
  );
}

export const useCameraGallery = () =>
  useContext<CameraGalleryContextProps>(CameraGalleryContext);
