import { api } from './config';

import { AuthLinkPasswordProps, AuthChangePasswordProps } from './types/auth';

export const sendToken = async (document_number: string) => {
  const { data, status } = await api.post('/v1/partners/login', {
    document_number,
  });

  if (status === 201) {
    return status;
  }

  return data;
};

export const login = async (token: string, document: string) => {
  const { data, status } = await api.post('/v1/partners/authenticator', {
    token: token,
    document_number: document,
  });

  if (status === 200 && data) {
    return data;
  }

  return false;
};

export const logout = async () => {
  const { status, data } = await api.post('/v1/logout');

  if (status === 200 && data) {
    return data;
  }

  return false;
};

export const sendLinkPassword = async (
  item: AuthLinkPasswordProps,
  user_type = 'partners'
) => {
  const { status, data } = await api.post(
    `/v1/${user_type}/recovery-password`,
    {
      email: item.email,
    }
  );

  if (status === 200 && data) {
    return data;
  }

  return false;
};

export const updatePassword = async (
  { email, token, password }: AuthChangePasswordProps,
  user_type = 'partners'
) => {
  const { status, data } = await api.put(
    `/v1/${user_type}/recovery-password/${email}/${token}`,
    { newPassword: password }
  );

  if (status === 200 && data) {
    return data;
  }

  return false;
};
