import { memo, useCallback } from 'react';
import { LayoutBase } from '../../../components/LayoutBase';
import { LabeledCheckbox } from '../../PlanPayment/components/LabeledCheckbox';
import { api } from '../../../services/config';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import cep from 'cep-promise';
import errorHandling from '../../../utils/error_handling';
import { Button, CreditCard, Input, Toast } from 'oialbert-ui';
import { createCard } from '../../../services/cards';
import { AxiosError } from 'axios';
import { useHistory } from 'react-router';
import { Invoice } from '../../../types/invoice';

const PAYMENT_METHOD_TYPE: any = {
  pix: 'Pix',
  boleto: 'Boleto',
  credit_card: 'Cartão de crédito',
};

const pixSchema = z.object({
  payment_method: z.literal('pix'),
  creditCard: z
    .object({
      holder_name: z.string().optional(),
      holder_document: z.string().optional(),
      number: z.string().optional(),
      exp_year: z.string().optional(),
      exp_month: z.string().optional(),
      cvv: z.string().optional(),
      billing_address: z.object({
        city: z.string().optional(),
        state: z.string().optional(),
        zip_code: z.string().optional(),
        country: z.string().optional(),
        line_1: z.string().optional(),
        line_2: z.string().optional(),
      }),
    })
    .optional(),
});

const boletoSchema = z.object({
  payment_method: z.literal('boleto'),
  creditCard: z
    .object({
      holder_name: z.string().optional(),
      holder_document: z.string().optional(),
      number: z.string().optional(),
      exp_year: z.string().optional(),
      exp_month: z.string().optional(),
      cvv: z.string().optional(),
      billing_address: z.object({
        city: z.string().optional(),
        state: z.string().optional(),
        zip_code: z.string().optional(),
        country: z.string().optional(),
        line_1: z.string().optional(),
        line_2: z.string().optional(),
      }),
    })
    .optional(),
});

const creditSchema = z.object({
  payment_method: z.literal('credit_card'),
  creditCard: z.object({
    holder_name: z
      .string()
      .regex(
        new RegExp('^[aA-zZ0-9çÇs ]+$'),
        'Esse campo não aceita acentuações e caracteres especiais'
      )
      .nonempty({ message: 'Nome é obrigatório' }),
    holder_document: z.string().nonempty({ message: 'CPF é obrigatório' }),
    number: z.string().nonempty({ message: 'Número do cartão é obrigatório' }),
    exp_year: z
      .string()
      .nonempty({ message: 'Ano de expiração é obrigatório' })
      .length(2, { message: 'Mês de expiração inválido' })
      .refine(
        (value) => {
          const year = Number(value);

          return year >= new Date().getFullYear() % 100;
        },
        { message: 'Ano de expiração inválido' }
      ),
    exp_month: z
      .string()
      .nonempty({ message: 'Mês de expiração é obrigatório' })
      .length(2, { message: 'Mês de expiração inválido' })
      .refine(
        (value) => {
          const month = Number(value);
          return month >= 1 && month <= 12;
        },
        { message: 'Mês de expiração inválido' }
      ),
    cvv: z.string().nonempty({ message: 'CVV é obrigatório' }),
    billing_address: z.object({
      city: z.string().nonempty({ message: 'Cidade é obrigatório' }),
      state: z.string().nonempty({ message: 'Estado é obrigatório' }),
      zip_code: z.string().nonempty({ message: 'CEP é obrigatório' }),
      country: z.string().nonempty({ message: 'País é obrigatório' }).length(2),
      line_1: z.string().nonempty({ message: 'Endereço é obrigatório' }),
      line_2: z.string().optional(),
    }),
  }),
});

const schema = z.discriminatedUnion('payment_method', [
  creditSchema,
  pixSchema,
  boletoSchema,
]);

export type FormValues = z.infer<typeof schema>;

const AddPaymentForm = memo(() => {
  const { goBack, location, push } = useHistory();

  const invoice = location.state as Invoice;

  if (!invoice) {
    push('/cards');
  }

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    setValue,
    register,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      payment_method: invoice?.payment_method as
        | 'pix'
        | 'boleto'
        | 'credit_card',
      creditCard: undefined,
    },
    mode: 'onChange',
    resolver: zodResolver(schema),
  });

  const paymentMethodWatch = watch('payment_method');
  const creditCardWatch = watch('creditCard');

  const searchZipcode = useCallback(
    async (zipCode: string) => {
      try {
        const { city, state, street } = await cep(zipCode);

        setValue('creditCard.billing_address.city', city);
        setValue('creditCard.billing_address.state', state);
        setValue('creditCard.billing_address.line_1', street);
      } catch (err) {
        errorHandling(null, 'não foi possível encontrar o cep', 'crema');
      }
    },
    [setValue]
  );

  const onSubmit = async (data: FormValues) => {
    try {
      await api.put(`/v2/partners/gateway/invoices/${invoice?.id}`, {
        payment_method: data.payment_method,
      });

      if (data.payment_method === 'credit_card' && data.creditCard) {
        await createCard(data.creditCard);
      }

      Toast.success('forma de pagamento adicionado com sucesso');
      goBack();
    } catch (err: any) {
      const error = err as AxiosError;

      if (error.response?.data?.message) {
        const { message } = error.response.data;

        Toast.crema(message);
      } else {
        Toast.crema('ocorreu um erro ao adicionar cartão');
      }
    }
  };

  return (
    <LayoutBase title="adicionar forma de pagamento">
      <section className="mt-5 px-5">
        <form onSubmit={handleSubmit(onSubmit)}>
          <h1 className="text-lg font-semibold text-center my-3 flex px-4">
            escolha sua forma de pagamento
          </h1>

          <div className="flex px-4 gap-3">
            {invoice?.product_copy?.payment_methods?.map((method) => (
              <LabeledCheckbox
                key={method}
                checked={paymentMethodWatch === method}
                name="selectedPaymentMethod"
                label={PAYMENT_METHOD_TYPE[method]}
                onChange={() =>
                  setValue(
                    'payment_method',
                    method as 'pix' | 'boleto' | 'credit_card'
                  )
                }
              />
            ))}
          </div>

          <div className="mt-8">
            {paymentMethodWatch === 'credit_card' && (
              <>
                <section className="w-full flex items-center justify-center mb-8">
                  <CreditCard
                    width="360px"
                    number={creditCardWatch?.number || '0000 0000 0000 0000'}
                    holder={creditCardWatch?.holder_name || 'Nome do titular'}
                    validate={`${creditCardWatch?.exp_month || '00'}/${
                      creditCardWatch?.exp_year || '00'
                    }`}
                    code={creditCardWatch?.cvv || '0000'}
                  />
                </section>
                <section className="flex flex-col mt-3 mb-4 space-y-4">
                  <Input
                    {...register('creditCard.number')}
                    type="text"
                    placeholder="0000 0000 0000 0000"
                    //mask="NUMBER_CARD"
                    error={errors?.creditCard?.number?.message}
                  />
                  <Input
                    {...register('creditCard.holder_name')}
                    placeholder="Nome do titular"
                    error={errors?.creditCard?.holder_name?.message}
                  />
                  <Input
                    {...register('creditCard.holder_document', {
                      setValueAs: (value) => value.replace(/[_\.-]/g, ''),
                    })}
                    placeholder="CPF"
                    mask="CPF"
                    error={errors?.creditCard?.holder_document?.message}
                  />
                  <section className="flex items-center space-x-4">
                    <Input
                      {...register('creditCard.exp_month')}
                      type="text"
                      placeholder="Mês (ex: 05)"
                      minLength={2}
                      maxLength={2}
                      error={errors?.creditCard?.exp_month?.message}
                    />
                    <Input
                      {...register('creditCard.exp_year')}
                      type="text"
                      placeholder="Ano (ex: 25)"
                      minLength={2}
                      maxLength={2}
                      error={errors?.creditCard?.exp_year?.message}
                    />
                  </section>

                  <Input
                    {...register('creditCard.cvv')}
                    placeholder="CVV"
                    type="text"
                    minLength={3}
                    maxLength={4}
                    error={errors?.creditCard?.cvv?.message}
                  />

                  <p className="text-md text-gray-500 font-normal my-4 ">
                    Endereço de cobrança
                  </p>
                  <Input
                    {...register('creditCard.billing_address.zip_code', {
                      setValueAs: (value) => value.replace(/[_\.-]/g, ''),
                    })}
                    placeholder="CEP"
                    mask="CEP"
                    error={
                      errors?.creditCard?.billing_address?.zip_code?.message
                    }
                    onBlur={(e) => {
                      const zipCode = e.target.value;
                      const { length } = zipCode;

                      if (length === 9) {
                        searchZipcode(zipCode);
                      }
                    }}
                  />
                  <Input
                    {...register('creditCard.billing_address.city')}
                    className="w-1/12"
                    placeholder="Cidade"
                    error={errors?.creditCard?.billing_address?.city?.message}
                  />
                  <section className="flex items-center space-x-4">
                    <Input
                      {...register('creditCard.billing_address.state')}
                      placeholder="Estado (ex: SP)"
                      error={
                        errors?.creditCard?.billing_address?.state?.message
                      }
                    />
                    <Input
                      {...register('creditCard.billing_address.country')}
                      placeholder="País (ex: BR)"
                      minLength={2}
                      maxLength={2}
                      error={
                        errors?.creditCard?.billing_address?.country?.message
                      }
                    />
                  </section>
                  <Input
                    {...register('creditCard.billing_address.line_1')}
                    placeholder="Endereço"
                    error={errors?.creditCard?.billing_address?.line_1?.message}
                  />
                  <Input
                    {...register('creditCard.billing_address.line_2')}
                    placeholder="Complemento"
                    error={errors?.creditCard?.billing_address?.line_2?.message}
                  />
                </section>
              </>
            )}
          </div>

          <Button
            type="submit"
            variant="solid"
            color="neon"
            full
            disabled={isSubmitting}
          >
            Salvar
          </Button>
        </form>
      </section>
    </LayoutBase>
  );
});

export default AddPaymentForm;
