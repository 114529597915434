import { Device } from '@capacitor/device';
import { PushNotifications } from '@capacitor/push-notifications';
import { SplashScreen } from '@capacitor/splash-screen';
import {
  IonApp,
  IonNav,
  isPlatform,
  setupIonicReact,
  useIonAlert,
} from '@ionic/react';
import { createBrowserHistory } from 'history';
import 'oialbert-ui/lib/styles/main.css';
import { Suspense, useCallback, useEffect } from 'react';
import { Router } from 'react-router';
import { Loading } from './components/Loading';
import AuthProvider from './contexts/AuthContext';
import CameraGalleryProvider from './contexts/CameraGalleryContext';
import Routes from './routes';

import '@ionic/react/css/core.css';
import '@ionic/react/css/structure.css';

import './safeArea.css';
import './styles/Swiper.css';

import { Toast } from 'oialbert-ui';
import './index.css';
import { storeData } from './utils/storage';
import { Capacitor } from '@capacitor/core';

const history = createBrowserHistory();

const TestMode = () => (
  <section className="w-full flex fixed top-0 left-0 bg-neon-900 z-10 items-center justify-center text-white text-sm font-bold">
    ambiente de testes
  </section>
);

const App = () => {
  const [presentAlert] = useIonAlert();

  const register = useCallback(async () => {
    await PushNotifications.register();
  }, []);

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) return;

    Device.getInfo().then((info) => {
      info.platform !== 'web' &&
        PushNotifications.checkPermissions().then((res) => {
          if (res.receive !== 'granted') {
            PushNotifications.requestPermissions().then((res) => {
              if (res.receive === 'denied') {
                presentAlert({
                  header: 'notificações',
                  message:
                    'você precisa dar permissões para receber notificações.',
                  buttons: ['entendi'],
                });
              } else {
                register();
              }
            });
          } else {
            register();
          }
        });
    });

    const tokenRegistrationListener = PushNotifications.addListener(
      'registration',
      (token) => {
        if (token) {
          storeData('AlbertPartners::deviceToken', token.value);
        }
      }
    );

    const registrationErrorListener = PushNotifications.addListener(
      'registrationError',
      (error) => {
        console.log('Registration error', error);
      }
    );

    const pushNotificationReceivedListener = PushNotifications.addListener(
      'pushNotificationReceived',
      (notification) => {
        console.log('Push notification received', notification);
      }
    );

    const pushNotificationActionPerformedListener =
      PushNotifications.addListener(
        'pushNotificationActionPerformed',
        (notification) => {
          if (notification.notification.data?.deep_link_url) {
            const { deep_link_url } = notification.notification.data;
            const [, route] = deep_link_url.split('.br');
            if (route) {
              window.location.href = route;
            }
          }
        }
      );

    return () => {
      tokenRegistrationListener.remove();
      registrationErrorListener.remove();
      pushNotificationReceivedListener.remove();
      pushNotificationActionPerformedListener.remove();
    };
  }, [presentAlert, register]);

  useEffect(() => {
    SplashScreen.hide();
    setupIonicReact({
      animated: isPlatform('mobile'),
      mode: isPlatform('ios') ? 'ios' : 'md',
      rippleEffect: true,
    });
  }, []);

  return (
    <IonApp className="safe-area-t safe-area-b bg-white">
      <IonNav />
      {process.env.REACT_APP_DEMO_MODE &&
        process.env.REACT_APP_DEMO_MODE === 'true' && <TestMode />}
      <Suspense fallback={<Loading />}>
        <Router history={history}>
          <AuthProvider>
            <CameraGalleryProvider>
              {/* <ProductContextProvider>
                    <CategoryContextProvider> */}
              <Routes />
              <Toast.Toaster />
              {/* </CategoryContextProvider>
                  </ProductContextProvider> */}
            </CameraGalleryProvider>
          </AuthProvider>
        </Router>
      </Suspense>
    </IonApp>
  );
};

export default App;
